import { observer } from "mobx-react"
import { useVideoContext } from "../../contexts/video-context"
import SingleHomePageCreator from "./single-home-page-creator"

function HomePageCreatorsMap() {
	const videosClass = useVideoContext()

	return (
		<>
			<div className="text-zinc-950 dark:text-zinc-50 w-full">
				<div className="mt-6 mb-3">Popular Creators</div>
				<div className="grid grid-cols-4 gap-2">
					{videosClass.homeScreenCreators.map(singleCreator => (
						<SingleHomePageCreator
							key={singleCreator.creatorUsername}
							singleHomePageCreator={singleCreator}
						/>
					))}
				</div>
			</div>
			<div className="border-b my-3 dark:border-zinc-700 border-zinc-300"/>
		</>
	)
}

export default observer(HomePageCreatorsMap)
