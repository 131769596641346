import { useCallback } from "react"
import { observer } from "mobx-react"
import { useSolanaContext } from "../../../../contexts/solana-context"

interface Props {
	searchResultsUsername: string
}

function SingleUsernameSearch(props: Props) {
	const { searchResultsUsername } = props
	const solanaClass = useSolanaContext()

	const selectUsername = useCallback(() => {
		solanaClass.updateMoneyTransferDetails("isUsernameSelected", true)
		solanaClass.updateMoneyTransferDetails("username", searchResultsUsername)
	}, [solanaClass, searchResultsUsername])

	return (
		<div>
			<span
				onClick={selectUsername}
				className="hover:underline cursor-pointer text-zinc-950 dark:text-zinc-200"
			>
				{searchResultsUsername}
			</span>
		</div>
	)
}

export default observer(SingleUsernameSearch)
