import { observer } from "mobx-react"
import { useCallback, useMemo } from "react"
import { FaShoppingBag } from "react-icons/fa"
import NewWalletBalanceTemplate from "../new-wallet-balance-template"
import { useAbbreviatedDateFormatter } from "../../../hooks/date-formatter"
import ShowProvidedUsdOrSolPrice from "../../usd-or-sol/show-provided-usd-or-sol-price"
import useNavigateToVideoNewPage from "../../../hooks/navigate/navigate-to-video-new-page"
import { usePositionsAndTransactionsContext } from "../../../contexts/positions-and-transactions-context"

interface Props {
	exclusiveContentPurchase: MyPurchasedExclusiveContent
}

function SingleContentPurchaseTransaction(props: Props) {
	const { exclusiveContentPurchase } = props
	const navigateToVideoNewPage = useNavigateToVideoNewPage()
	const abbreviatedDateFormatter = useAbbreviatedDateFormatter()
	const positionsAndTransactionsClass = usePositionsAndTransactionsContext()

	const { purchaseDate, priceInSol, priceInUsd, uuid, newWalletBalanceSol, newWalletBalanceUsd, videoName } = exclusiveContentPurchase

	const setTransactionIdToFocusOn = useCallback(() => {
		positionsAndTransactionsClass.updateTransactionToFocusOn(uuid)
	}, [uuid, positionsAndTransactionsClass])

	const isCurrentTransactionFocusedOn = useMemo(() => {
		return positionsAndTransactionsClass.transactionIdToFocusOn === uuid
	}, [positionsAndTransactionsClass.transactionIdToFocusOn, uuid])

	return (
		<div
			className={`grid grid-cols-8 gap-4 py-2.5
			text-zinc-950 dark:text-zinc-200 border-b border-zinc-200 dark:border-zinc-800 cursor-pointer text-sm
			${isCurrentTransactionFocusedOn ?
			"bg-zinc-200 dark:bg-zinc-700 hover:bg-zinc-300 dark:hover:bg-zinc-600" : "bg-inherit hover:bg-zinc-100 dark:hover:bg-zinc-800"}
			`}
			onClick={setTransactionIdToFocusOn}
		>
			<div className="col-span-1 flex items-center">
				{abbreviatedDateFormatter(purchaseDate)}
			</div>
			<div className="col-span-2 flex items-center">
				<div className="flex flex-row items-center space-x-3 text-blue-600 dark:text-blue-400">
					<FaShoppingBag size={30} className="flex-shrink-0"/>
					<div>Content Purchase</div>
				</div>
			</div>
			<div className="col-span-1 flex items-center">
				<div className="flex justify-start text-blue-600 dark:text-blue-400">
					-
					<ShowProvidedUsdOrSolPrice
						solPriceToDisplay={priceInSol}
						usdPriceToDisplay={priceInUsd}
						roundOrFixed="fixed"
					/>
				</div>
			</div>
			<div className="col-span-3 flex items-center overflow-hidden">
				<div className="flex flex-row overflow-hidden text-ellipsis whitespace-nowrap">
					<div className="flex-shrink-0">Purchased Exclusive Access to&nbsp;</div>
					<div
						className="cursor-pointer underline decoration-dotted
						hover:decoration-solid overflow-hidden text-ellipsis whitespace-nowrap"
						onClick={() => navigateToVideoNewPage(uuid)}
					>
						{videoName}
					</div>
				</div>
			</div>
			<div className="col-span-1 flex justify-end items-center">
				<NewWalletBalanceTemplate
					newWalletBalanceSol={newWalletBalanceSol}
					newWalletBalanceUsd={newWalletBalanceUsd}
				/>
			</div>
		</div>
	)
}

export default observer(SingleContentPurchaseTransaction)
