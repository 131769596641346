import _ from "lodash"
import { observer } from "mobx-react"
import { useCallback, useEffect, useState } from "react"
import SaveButton from "../../save-button"
import useEditVideoName from "../../../../hooks/creator/video-name/edit-video-name"
import useAssignDefaultVideoName from "../../../../hooks/creator/video-name/assign-default-video-name"

interface Props {
	content: MyContent
}

function VideoNameTextInput(props: Props) {
	const { content } = props
	const maxLength = 100
	const [videoName, setVideoName] = useState("")
	const editVideoName = useEditVideoName()
	const assignDefaultVideoName = useAssignDefaultVideoName()

	useEffect(() => {
		assignDefaultVideoName(content.uuid, setVideoName)
	}, [assignDefaultVideoName, content.uuid])

	const handleSaveVideoName = useCallback(async () => {
		if (!_.isEmpty(videoName)) await editVideoName(content, videoName, setVideoName)
		else assignDefaultVideoName(content.uuid, setVideoName)
	}, [videoName, editVideoName, content, assignDefaultVideoName])

	const handleChange = useCallback((event: React.ChangeEvent<HTMLInputElement>) => {
		const value = event.target.value
		if (value.length > maxLength) return
		setVideoName(value)
	}, [maxLength, setVideoName])

	const handleKeyDown = useCallback((event: React.KeyboardEvent<HTMLInputElement>) => {
		if ((event.ctrlKey || event.metaKey) && event.key === "Enter") {
			handleSaveVideoName()
		}
	}, [handleSaveVideoName])

	return (
		<div className="flex items-center w-full">
			<div className="relative flex flex-col flex-grow">
				<label className="text-sm text-zinc-700 dark:text-zinc-300 ml-0.5 font-semibold">
					Name
				</label>
				<input
					type="text"
					className={
						`mt-1 p-1 border rounded text-zinc-950 dark:text-zinc-200 \
						bg-white dark:bg-zinc-800 outline-none font-normal w-full
						${videoName.length === maxLength ?
			"border-red-500 dark:border-red-500" : "border-zinc-200 dark:border-zinc-700"}`
					}
					value={videoName}
					onChange={handleChange}
					onKeyDown={handleKeyDown}
					maxLength={maxLength}
				/>
				<span className="text-xs text-zinc-600 dark:text-zinc-400 mt-1 ml-0.5">
					{videoName.length}/{maxLength}
				</span>
			</div>
			{(!_.isEmpty(videoName) && (videoName !== content.videoName)) && (
				<SaveButton
					handleSaveButton={handleSaveVideoName}
					extraClasses="mt-0.5 ml-2"
					customCirclePixelSize="33px"
				/>
			)}
		</div>
	)
}

export default observer(VideoNameTextInput)
