import { AxiosResponse } from "axios"
import FortunaHttpClient from "../classes/fortuna-http-client"

export default class CreatorDataService {
	private readonly pathHeader: PathHeaders = "/creator"

	constructor(private readonly httpClient: FortunaHttpClient) {
	}

	async getMyContent(): Promise<AxiosResponse<RetrieveMyContentResponse | MessageResponse | ErrorResponse>> {
		return await this.httpClient.http.get<RetrieveMyContentResponse | MessageResponse | ErrorResponse>(
			`${this.pathHeader}/get-creator-content-list`
		)
	}

	async createVideo(newVideoData: CreateVideo): Promise<AxiosResponse<CreateVideoResponse | NonSuccessResponse>> {
		return await this.httpClient.http.post<CreateVideoResponse | NonSuccessResponse>(
			`${this.pathHeader}/create-video`, { newVideoData }
		)
	}

	async getCreatorInfo(): Promise<AxiosResponse<CreatorInfoResponse | ErrorResponse>> {
		return await this.httpClient.http.get<CreatorInfoResponse | ErrorResponse>(
			`${this.pathHeader}/get-creator-info`
		)
	}

	async editChannelName(channelName: string): Promise<AxiosResponse<SuccessResponse | ErrorResponses>> {
		return await this.httpClient.http.post<SuccessResponse | ErrorResponses>(
			`${this.pathHeader}/edit-channel-name`, { channelName }
		)
	}

	async addOrEditChannelDescription(channelDescription: string): Promise<AxiosResponse<SuccessResponse | ErrorResponses>> {
		return await this.httpClient.http.post<SuccessResponse | ErrorResponses>(
			`${this.pathHeader}/add-or-edit-channel-description`, { channelDescription }
		)
	}

	async addOrEditSocialPlatformLink(
		socialLink: string,
		socialPlatform: SocialPlatformKey
	): Promise<AxiosResponse<SuccessResponse | ErrorResponses>> {
		return await this.httpClient.http.post<SuccessResponse | ErrorResponses>(
			`${this.pathHeader}/add-or-edit-social-platform-link`, { socialLink, socialPlatform }
		)
	}

	async removeSocialPlatformLink(socialPlatform: SocialPlatformKey): Promise<AxiosResponse<SuccessResponse | ErrorResponses>> {
		return await this.httpClient.http.post<SuccessResponse | ErrorResponses>(
			`${this.pathHeader}/remove-social-platform-link/${socialPlatform}`
		)
	}

	async removeCurrentProfilePicture(): Promise<AxiosResponse<SuccessResponse | ErrorResponse>> {
		return await this.httpClient.http.post<SuccessResponse | ErrorResponse>(
			`${this.pathHeader}/remove-current-profile-picture`
		)
	}

	async removeCurrentChannelBannerPicture(): Promise<AxiosResponse<SuccessResponse | ErrorResponse>> {
		return await this.httpClient.http.post<SuccessResponse | ErrorResponse>(
			`${this.pathHeader}/remove-current-channel-banner-picture`
		)
	}

	async updateVideoListingStatus(videoId: number): Promise<AxiosResponse<AllCommonResponses>> {
		return await this.httpClient.http.post<AllCommonResponses>(
			`${this.pathHeader}/update-video-listing-status/${videoId}`
		)
	}

	async editVideoName(videoName: string, videoId: number): Promise<AxiosResponse<AllCommonResponses>> {
		return await this.httpClient.http.post<AllCommonResponses>(
			`${this.pathHeader}/edit-video-name`, { videoName, videoId }
		)
	}

	async editVideoDescription(videoDescription: string, videoId: number): Promise<AxiosResponse<AllCommonResponses>> {
		return await this.httpClient.http.post<AllCommonResponses>(
			`${this.pathHeader}/edit-video-description`, { videoDescription, videoId }
		)
	}

	async featureVideo(videoIdToFeature: number, videoIdToUnfeature?: number): Promise<AxiosResponse<SuccessResponse | ErrorResponses>> {
		return await this.httpClient.http.post<SuccessResponse | ErrorResponses>(
			`${this.pathHeader}/feature-video`, { videoIdToFeature, videoIdToUnfeature }
		)
	}

	async unfeatureVideo(videoIdToUnfeature: number): Promise<AxiosResponse<SuccessResponse | ErrorResponses>> {
		return await this.httpClient.http.post<SuccessResponse | ErrorResponses>(
			`${this.pathHeader}/unfeature-video`, { videoIdToUnfeature }
		)
	}

	async addVideoTag(videoTag: string, videoId: number): Promise<AxiosResponse<AddVideoTag | ErrorResponses>> {
		return await this.httpClient.http.post<AddVideoTag | ErrorResponses>(
			`${this.pathHeader}/add-video-tag`, { videoTag, videoId }
		)
	}

	async deleteVideoTag(videoTagId: number, videoId: number): Promise<AxiosResponse<SuccessResponse | ErrorResponses>> {
		return await this.httpClient.http.post<SuccessResponse | ErrorResponses>(
			`${this.pathHeader}/delete-video-tag`, { videoTagId, videoId }
		)
	}
}
