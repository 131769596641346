import DisplayUsernames from "./display-usernames"
import UsernameSearchInput from "./username-search-input"

export default function UsernameSearch() {
	return (
		<>
			<UsernameSearchInput />
			<DisplayUsernames />
		</>
	)
}
