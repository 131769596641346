import { useCallback } from "react"
import { IoIosArrowDown, IoIosArrowUp } from "react-icons/io"
import SolOrUsdSlider from "../../sliders/sol-or-usd-slider"

interface Props {
	isOpen: boolean
	setIsOpen: React.Dispatch<React.SetStateAction<boolean>>
}

export default function WalletSummaryHeader(props: Props) {
	const { isOpen, setIsOpen } = props

	const toggleContent = useCallback(() => {
		setIsOpen(prevState => !prevState)
	}, [setIsOpen])

	return (
		<div
			className="border-b border-zinc-300 dark:border-zinc-700 cursor-pointer"
			onClick={toggleContent}
		>
			<div className="flex justify-between items-center mb-2">
				<div className="text-2xl font-semibold">Wallet</div>
				<div className="flex items-center">
					<SolOrUsdSlider />
					<div className="ml-2">
						{isOpen ? (
							<IoIosArrowUp size={20}/>
						) : (
							<IoIosArrowDown size={20}/>
						)}
					</div>
				</div>
			</div>
		</div>
	)
}
