import { observer } from "mobx-react"
import SingleCreatorPageVideo from "./single-creator-page-video"
import useCreatorVideosToShow from "../../../hooks/videos/creator-videos-to-show"

interface Props {
	videoData: VideoDataWithUrlRetrievalStatus[]
}

function CreatorPageVideoMap(props: Props) {
	const { videoData } = props
	const creatorVideosToShow = useCreatorVideosToShow(videoData)

	return (
		<>
			{creatorVideosToShow.map(singleVideoData => (
				<div key={singleVideoData.uuid} className="flex flex-col items-center">
					<SingleCreatorPageVideo videoData={singleVideoData} />
				</div>
			))}
		</>
	)
}

export default observer(CreatorPageVideoMap)
