import { useMemo } from "react"
import { usePositionsAndTransactionsContext } from "../../contexts/positions-and-transactions-context"

export default function useCheckIfUUIDExistsInExclusiveContentList(uuid: string | undefined): boolean {
	const positionsAndTransactionsClass = usePositionsAndTransactionsContext()

	return useMemo(() => {
		return positionsAndTransactionsClass.checkIfUuidExistsInExclusiveContentList(uuid)
	// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [positionsAndTransactionsClass, positionsAndTransactionsClass.myPurchasedExclusiveContent, uuid])
}
