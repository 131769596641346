import { FaXTwitter } from "react-icons/fa6"
import { SiApplemusic } from "react-icons/si"
import { FaYoutube, FaInstagram, FaFacebook, FaSpotify, FaTwitch, FaSoundcloud, FaTiktok } from "react-icons/fa"

const platformIcons = {
	youtube: FaYoutube,
	twitter: FaXTwitter,
	twitch: FaTwitch,
	spotify: FaSpotify,
	soundcloud: FaSoundcloud,
	applemusic: SiApplemusic,
	instagram: FaInstagram,
	facebook: FaFacebook,
	tiktok: FaTiktok
}

export const SocialPlatforms = {
	youtube: "youtube",
	twitter: "twitter",
	twitch: "twitch",
	spotify: "spotify",
	soundcloud: "soundcloud",
	applemusic: "applemusic",
	instagram: "instagram",
	facebook: "facebook",
	tiktok: "tiktok"
}

export default platformIcons
