import { AxiosResponse } from "axios"
import FortunaHttpClient from "../classes/fortuna-http-client"

export default class SolanaDataService {
	private readonly pathHeader: PathHeaders = "/solana"

	constructor(private readonly httpClient: FortunaHttpClient) {
	}

	async moneyTransferToUsername(
		moneyTransferData: MoneyTransferData
	): Promise<AxiosResponse<TransferFundsResponse | NonSuccessResponse>> {
		return await this.httpClient.http.post<TransferFundsResponse | NonSuccessResponse>(
			`${this.pathHeader}/money-transfer-to-username`, { moneyTransferData }
		)
	}

	async moneyTransferToPublicKey(
		moneyTransferData: MoneyTransferData
	): Promise<AxiosResponse<TransferFundsResponse | NonSuccessResponse>> {
		return await this.httpClient.http.post<TransferFundsResponse | NonSuccessResponse>(
			`${this.pathHeader}/money-transfer-to-public-key`, { moneyTransferData }
		)
	}

	async getSolPrice(): Promise<AxiosResponse<SolPriceResponse | ErrorResponse>> {
		return await this.httpClient.http.get<SolPriceResponse | ErrorResponse>(
			`${this.pathHeader}/get-sol-price`, { headers: { "No-Auth-Required": "true" }}
		)
	}
}
