import _ from "lodash"
import { useMemo } from "react"
import { observer } from "mobx-react"
import { useCreatorContext } from "../../../contexts/creator-context"

interface Props {
	tierNumber: number
}

function ShowTierDiscount(props: Props) {
	const { tierNumber } = props
	const creatorClass = useCreatorContext()

	const tierAccessPriceUsd = useMemo(() => {
		return creatorClass.newVideoDetails.tierData[tierNumber - 1].tierAccessPriceUsd || 0
	// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [creatorClass.newVideoDetails.tierData[tierNumber - 1]?.tierAccessPriceUsd, tierNumber])

	const previousTierAccessPriceUsd = useMemo(() => {
		return creatorClass.newVideoDetails.tierData[tierNumber]?.tierAccessPriceUsd || null
	// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [creatorClass.newVideoDetails.tierData[tierNumber]?.tierAccessPriceUsd, tierNumber])

	const impliedTierDiscount = useMemo(() => {
		if (_.isNull(previousTierAccessPriceUsd)) return -1
		return  100 * (previousTierAccessPriceUsd - tierAccessPriceUsd) / (previousTierAccessPriceUsd)
	}, [previousTierAccessPriceUsd, tierAccessPriceUsd])

	if (
		_.isNull(previousTierAccessPriceUsd) ||
		impliedTierDiscount <= 0
	) return null

	return (
		<>
			&nbsp;({impliedTierDiscount.toFixed(2)}% discount relative to tier {tierNumber + 1})
		</>
	)
}

export default observer(ShowTierDiscount)
